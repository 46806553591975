<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" :show-error="showError" />
    </router-view>
</template>

<script lang="ts" setup>
import { handleError } from '@signal24/vue-foundation';

import { useStore } from '@/store';

const rootStore = useStore();

function showError() {
    handleError(new Error('ZD Startup Error'));
    rootStore.globalError = `Something's not right. Please check your Internet connection and try again. If the issue persists, contact support@zyno.app.`;
}
</script>

<style lang="scss" scoped>
#desktop {
    @apply flex-1 flex flex-col items-center justify-center gap-8;
}

.status {
    @apply text-white text-xl font-semibold;
}
</style>
