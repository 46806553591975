import type { Router } from 'vue-router';

import { GlobalState, type ITenant } from '@/global';
import { type IIdentityResponse, type IInstall, type ILoginResponse, IdentityApi, PermissionsApi } from '@/openapi-clients-generated/auth';
import { useStore } from '@/store';
import { dataFrom } from '@signal24/openapi-client-codegen/browser';
import { Logger } from './logger';

const logger = Logger.createScoped('AuthService');

class AuthService {
    authStorage: Storage = localStorage;
    activeJwt: string | null = null;
    activeJwtExp: number | null = null;
    logoutCause: string | undefined;

    constructor() {
        this.loadStoredJwt();
    }

    // INIT

    private loadStoredJwt() {
        const ssJwt = this.authStorage.getItem('zs:auth:sessionJwt');
        if (!ssJwt) return;

        const ssJwtExp = Number.parseInt(this.authStorage.getItem('zs:auth:sessionJwtExp')!);
        if (ssJwtExp <= Date.now()) {
            logger.info('Stored JWT has expired');
            return this.clearJwt();
        }

        this.activeJwt = ssJwt;
        this.activeJwtExp = ssJwtExp;
        logger.info('Loaded stored JWT');
    }

    clearJwt() {
        this.activeJwt = null;
        this.activeJwtExp = null;
        this.authStorage.removeItem('zs:auth:sessionJwt');
        this.authStorage.removeItem('zs:auth:sessionJwtExp');
        logger.info('Cleared stored JWT');
    }

    // STATE SETUP

    setInstall(install: Pick<IInstall, 'id'>) {
        this.authStorage = sessionStorage;
        GlobalState.install.value = install;
        logger.info(`Set install to ${install.id}`);
    }

    setUser(user: IIdentityResponse) {
        GlobalState.user.value = user;

        const tenant = user.tenants.find(t => t.id === user.tenantId)!;
        this.setTenant(tenant);

        logger.info(`Set user to ${user.id}`);
    }

    setTenant(tenant: Pick<ITenant, 'id' | 'name' | 'isAdmin'>) {
        GlobalState.tenant.value = tenant;
        this.authStorage.setItem('zs:auth:tenantId', tenant.id);
        logger.info(`Set tenant to ${tenant.id}`);
    }

    // SESSION MANAGEMENT

    async processLoginResponse(response: ILoginResponse) {
        this.setUser(response.identity);
        this.processRenewalResponse(response);
        await this.reloadPermissions();
    }

    async processRenewalResponse(response: Pick<ILoginResponse, 'jwt' | 'expiresAt'>) {
        this.activeJwt = response.jwt;
        this.activeJwtExp = response.expiresAt;

        this.authStorage.setItem('zs:auth:sessionJwt', response.jwt);
        this.authStorage.setItem('zs:auth:sessionJwtExp', String(response.expiresAt));

        logger.info('Stored renewal JWT');
    }

    clearUserSession(cause?: string) {
        GlobalState.user.value = null;

        if (!GlobalState.install.value) {
            GlobalState.tenant.value = null;
        }

        this.authStorage.removeItem('zs:auth:sessionJwt');
        this.authStorage.removeItem('zs:auth:sessionJwtExp');

        if (cause) {
            this.logoutCause = cause;
        }

        logger.info('Cleared user session', { cause });
    }

    // GETTERS

    get lastTenantId() {
        return this.authStorage.getItem('zs:auth:tenantId');
    }

    get logoutCauseOnce() {
        const cause = this.logoutCause;
        this.logoutCause = undefined;
        return cause;
    }

    // LOADERS

    async loadExistingSession() {
        if (!this.activeJwt) return;
        if (this.activeJwtExp! <= Date.now()) return this.clearJwt();

        try {
            const user = dataFrom(await IdentityApi.getIdentityGetIdentity());
            this.setUser(user);
            await this.reloadPermissions();
        } catch (_) {
            // the 401 from the API middlware will have already cleared the user session before we get here
        }
    }

    async reloadPermissions() {
        GlobalState.permissions.value = null;
        try {
            GlobalState.permissions.value = dataFrom(await PermissionsApi.getPermissionsGet());
            logger.info('Loaded user permissions');
        } catch (err) {
            useStore().globalError = 'There was an issue loading user permissions. Please check your Internet connection and refresh the page.';
        }
    }
}

export const Auth = new AuthService();
