import { type ComponentInternalInstance, getCurrentInstance, onBeforeUnmount, onMounted, onUnmounted } from 'vue';
import type { Router } from 'vue-router';

import { GlobalState } from '@/global';

import { LoginApi } from '@/openapi-clients-generated/auth';
import { OpenApiError, dataFrom } from '@signal24/openapi-client-codegen/browser';
import { useIdleTimeout } from '../helpers/idle.helpers';
import { Auth } from './auth.service';
import { Logger } from './logger';

const IdleTimeoutMs = import.meta.env.VITE_APP_AUTH_TIMEOUT_MINS * 60 * 1000;
const InstallIdleTimeoutMs = import.meta.env.VITE_APP_AUTH_TIMEOUT_MINS_INSTALL * 60 * 1000;
const IdleBlockingInstances = new Set<ComponentInternalInstance>();

const logger = Logger.createScoped('SessionManagement');

export class SessionManagement {
    static install() {
        this.useJwtRenewal();
        this.useSessionIdleTimeout();
    }

    static useJwtRenewal() {
        let jwtRefreshInterval: number | null = null;

        const refreshJwt = async () => {
            try {
                const response = dataFrom(await LoginApi.postLoginRenew());
                Auth.processRenewalResponse(response);
            } catch (err) {
                // if we already get a 401, it's too late
                if (err instanceof OpenApiError && err.response.status === 401) {
                    Auth.clearUserSession();
                    return;
                }

                // no need to do anything else. checkJwtRefresh runs every 30 seconds
                // so it'll automatically try again.
            }
        };

        const checkJwtRefresh = () => {
            const jwtExp = this.getJwtTimeout();
            if (!jwtExp) {
                return Auth.clearUserSession();
            }

            const renewTs = jwtExp - 300_000; /* 5 minutes */
            if (Date.now() > renewTs) {
                return refreshJwt();
            }
        };

        function scheduleJwtRefresh() {
            jwtRefreshInterval = window.setInterval(checkJwtRefresh, 30_000);
            checkJwtRefresh();
        }

        function clearScheduledJwtRefresh() {
            if (jwtRefreshInterval) clearInterval(jwtRefreshInterval);
        }

        onMounted(scheduleJwtRefresh);
        onUnmounted(clearScheduledJwtRefresh);
    }

    static useSessionIdleTimeout() {
        useIdleTimeout({
            timeoutMs: 60_000,
            onIdle: (idleMs: number) => {
                const idleTimeout = GlobalState.isInstall ? InstallIdleTimeoutMs : IdleTimeoutMs;
                if (idleMs < idleTimeout) return;
                if (!GlobalState.user.value) return;
                if (IdleBlockingInstances.size > 0) return;
                this.performTimeoutRedirect();
            }
        });
        window.addEventListener('focus', () => this.checkSession());
    }

    static checkSession() {
        if (!GlobalState.user.value) return;
        const jwtTimeout = this.getJwtTimeout();
        if (!jwtTimeout || jwtTimeout < Date.now()) this.performTimeoutRedirect();
    }

    static getJwtTimeout(): number | null {
        const jwtExp = Auth.authStorage.getItem('zs:auth:sessionJwtExp');
        if (!jwtExp || !/^\d+$/.test(jwtExp)) {
            return null;
        }
        return Number.parseInt(jwtExp);
    }

    static performTimeoutRedirect() {
        logger.info('Session timed out');
        Auth.clearUserSession('Your session timed out due to inactivity.');
    }

    static useSessionTimeoutDisabler() {
        const instance = getCurrentInstance();
        if (!instance) return;

        onMounted(() => {
            IdleBlockingInstances.add(instance);
        });
        onBeforeUnmount(() => {
            IdleBlockingInstances.delete(instance);
        });
    }
}
