<template>
    <div id="nav">
        <div class="logo" :class="{ active: logoActive }" @click="setLogoActive" @mouseenter="resetLogoActive" @mouseleave="resetLogoActive">
            <RouterLink to="/">
                Zyno<strong>{{ rootStore.rootAppName ?? 'Suite' }}</strong>
                <template v-if="rootStore.subAppName">&nbsp;{{ rootStore.subAppName }}</template>
            </RouterLink>
            <FLauncherPopover class="launcher-popover" />
        </div>

        <div id="#tab-bar" class="flex-1"></div>

        <div class="qab">
            <i id="nav-openHelp" v-tooltip="'Help'" class="fa-light fa-comment-question" @click="openHelp"></i>

            <i
                v-if="isImpersonating"
                class="fa-thin fa-user-secret"
                v-tooltip="`You\'re impersonating this user.\nClick to stop impersonating.`"
                @click="stopImpersonating"
            />
            <i v-else-if="mspTooltip" class="fa-thin fa-life-ring" v-tooltip="mspTooltip" @click="returnToMspTenant" />

            <i
                v-if="updateAvailable"
                v-animation="{ cls: 'fa-bounce', gap: 3 }"
                v-tooltip="'Click to update Zyno'"
                class="fa-solid fa-circle-up update-icon"
                @click="doUpdate"
            ></i>
            <User />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { presentOverlay, showWait } from '@signal24/vue-foundation';

import { useUpdateAvailableRef } from '@/shared/helpers/update.helpers';
import { useStore } from '@/store';

import { GlobalState } from '@/global';
import { LoginApi } from '@/openapi-clients-generated/auth';
import { withWait } from '@/shared/helpers/request.helpers';
import { Auth } from '@/shared/services/auth.service';
import { dataFrom } from '@signal24/openapi-client-codegen/browser';
import { computed, ref } from 'vue';
import MTenantSwitcher from '../auth/exports/m-tenant-switcher.vue';
// import OmniSearch from './omnisearch.vue';
import User from './qab/c-user.vue';
import FLauncherPopover from './qab/f-launcher-popover.vue';

// global keyboard event listener to open omnisearch
// TODO: clean up, add command+F w/ secondary browser forwarding, use proper selector (or singleton)
// window.addEventListener('keydown', e => {
//     if (e.key === 'k' && e.metaKey) {
//         e.preventDefault();
//         const modalWraps = document.querySelectorAll('.vf-modal-wrap');
//         const omnisearchModalWrap = Array.from(modalWraps).find(mw => mw.id === 'omnisearch');
//         if (omnisearchModalWrap) return;
//         openOmnisearch();
//     }
// });

// async function openOmnisearch() {
//     await presentOverlay(OmniSearch, {});
// }

const rootStore = useStore();
const updateAvailable = useUpdateAvailableRef();

const isImpersonating = computed(() => !!GlobalState.user.value?.impersonatorId);
const mspTooltip = computed(() => {
    if (!GlobalState.user.value?.mspId) return null;
    const message = GlobalState.user.value?.isSysAdmin
        ? `SysAdmin Mode\nYou're signed into a customer tenant.`
        : `MSP Mode\nYou're signed into a managed tenant.`;
    if (GlobalState.isInstall) return message;
    return `${message}\nClick to return to your tenant.`;
});

const logoActive = ref(false);
function setLogoActive() {
    logoActive.value = true;
}
function resetLogoActive() {
    logoActive.value = false;
}

function openHelp() {
    const FreshworksWidget = window.FreshworksWidget;
    FreshworksWidget('open');
}

async function stopImpersonating() {
    withWait('Deimpersonating...', async () => {
        const result = dataFrom(await LoginApi.postLoginDeimpersonateAccount());
        Auth.processRenewalResponse(result);
        await Auth.loadExistingSession();
        await Auth.reloadPermissions();
    });
}

function returnToMspTenant() {
    if (GlobalState.isInstall) return;
    presentOverlay(MTenantSwitcher, {
        autoSwitchToTenantId: GlobalState.user.value?.mspId
    });
}

function doUpdate() {
    showWait('Reloading Zyno...');
    location.reload();
}
</script>

<style lang="scss" scoped>
#nav {
    @apply flex justify-between items-center;

    .logo {
        @apply text-white;
    }

    .logo {
        > a,
        > a > strong {
            @apply text-2xl;
        }

        .launcher-popover {
            @apply absolute hidden z-[100];
        }

        &:hover:not(.active) .launcher-popover {
            @apply block;
        }
    }

    .qab {
        @apply flex items-center gap-2;

        i {
            @apply cursor-pointer text-white hover:opacity-50 text-3xl ml-2;
        }

        > :last-child {
            @apply ml-2;
        }
    }
}
</style>

<style lang="scss">
#freshworks-frame-wrapper {
    bottom: inherit !important;
    animation: none !important;
    top: 64px;
}
</style>
