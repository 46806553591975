import { OtelState } from '@/otel';
import { debounce } from 'lodash';

interface IScopedLogger {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    debug: (msg: string, ...args: any[]) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    info: (msg: string, ...args: any[]) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    warn: (msg: string, ...args: any[]) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    error: (msg: string, ...args: any[]) => void;
}

interface ILogger {
    createScoped(scope: string): IScopedLogger;
}

function createLogger(): ILogger {
    const LogLevelMap = {
        debug: 0,
        info: 1,
        warn: 2,
        error: 3
    } as const;

    interface LogEntry {
        aid: number;
        ts: number;
        level: number;
        scope?: string;
        msg: string;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data?: Record<string, any>;
    }

    const LogState = {
        queue: [] as LogEntry[],
        isPushing: false
    };

    const pushLogQueue = async () => {
        if (LogState.isPushing) {
            return;
        }

        while (LogState.queue.length > 1000) {
            LogState.queue.shift();
        }

        LogState.isPushing = true;

        // allow a little time for more logs to pour in
        await new Promise(resolve => setTimeout(resolve, 1_000));

        const entries = LogState.queue.slice(0, 100);
        const entriesOut = entries.map(entry => ({
            a: entry.aid,
            t: entry.ts,
            l: entry.level,
            s: entry.scope,
            m: entry.msg,
            x: entry.data
        }));

        try {
            if (import.meta.env.VITE_APP_SCRIBE_URL) {
                const response = await fetch(import.meta.env.VITE_APP_SCRIBE_URL, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        i: `zynosuite-spa/${import.meta.env.VITE_APP_ENV}`,
                        v: import.meta.env.VITE_APP_VERSION,
                        d: OtelState.installId,
                        u: OtelState.userId,
                        l: OtelState.launchTs,
                        e: entriesOut
                    })
                });

                if (response.status < 200 || response.status > 201) {
                    throw new Error(`Unexpected HTTP response code ${response.status}`);
                }
            }

            LogState.queue.splice(0, entries.length);
        } catch (err) {
            console.error('[Scribe] Failed to push logs', err);
        }

        LogState.isPushing = false;
        if (LogState.queue.length) setTimeout(pushLogQueue, 1_000);
    };

    const pushLogQueueDebounced = debounce(pushLogQueue, 5_000, { trailing: true });
    window.addEventListener('beforeunload', pushLogQueueDebounced);

    const createProxiedLogger = (originalFn: typeof console.log, level: keyof typeof LogLevelMap, scope: string) => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        return (msg: string, ...args: any[]) => {
            const argsCopy = [...args];

            LogState.queue.push({
                aid: 0,
                ts: Date.now(),
                level: LogLevelMap[level],
                scope,
                msg,
                data: argsCopy.length === 1 ? argsCopy[0] : argsCopy.length ? argsCopy : undefined
            });

            pushLogQueueDebounced();

            const localArgs = [`[ZS:${scope}]`, msg];
            if (args.length) localArgs.push(...args);
            originalFn.call(console, ...localArgs);
        };
    };

    const createScoped = (scope: string) => {
        return {
            info: createProxiedLogger(console.log, 'info', scope),
            warn: createProxiedLogger(console.warn, 'warn', scope),
            error: createProxiedLogger(console.error, 'error', scope),
            debug: createProxiedLogger(console.debug, 'debug', scope)
        };
    };

    return { createScoped };
}

function getLogger(): ILogger {
    interface ILoggerWindow {
        $scribe?: ILogger;
    }
    const loggerWindow = window as ILoggerWindow;
    if (loggerWindow.$scribe) {
        return loggerWindow.$scribe;
    }
    loggerWindow.$scribe = createLogger();
    return loggerWindow.$scribe;
}

export const Logger = getLogger();
