import { GlobalState } from '@/global';
import { PusherService } from '@/modules/core/exports/pusher';
import { Auth } from '@/shared/services/auth.service';
import { OpenReplay } from '@/shared/services/openreplay';
import type { Client } from '@hey-api/client-fetch';
import { client as apiAuthClient } from '../openapi-clients-generated/auth';
import { client as apiSuiteClient } from '../openapi-clients-generated/suite';
import { client as apiTalkClient } from '../openapi-clients-generated/talk';
import { configureZynoOpenApiClient } from './helpers';

export function configureApiClients() {
    apiAuthClient.setConfig({
        baseUrl: import.meta.env.VITE_APP_AUTH_API_URL
    });
    configureApiClient('auth', apiAuthClient);

    apiSuiteClient.setConfig({
        baseUrl: import.meta.env.VITE_APP_API_URL
    });
    configureApiClient('suite', apiSuiteClient);

    apiTalkClient.setConfig({
        baseUrl: import.meta.env.VITE_APP_TALK_API_URL
    });
    configureApiClient('talk', apiTalkClient);
}

function configureApiClient(name: string, apiClient: Client) {
    configureZynoOpenApiClient(name, apiClient, {
        headers: getApiRequestHeaders
    });
}

export function getApiRequestHeaders() {
    const authHeader = Auth.activeJwt ? { Authorization: `Bearer ${Auth.activeJwt}` } : {};

    const enableDevAuth = import.meta.env.VITE_APP_API_DEV_AUTH === 'true';

    return {
        ...authHeader,
        ...(enableDevAuth
            ? {
                  'x-zs-aid': GlobalState.user.value?.id,
                  'x-zs-tid': GlobalState.tenant.value?.id,
                  'x-zs-iid': GlobalState.install.value?.id,
                  'x-zs-tam': GlobalState.tenant.value?.isAdmin ? 'true' : undefined
                  // todo: add new headers
              }
            : {}),
        'x-openreplay-sessionid': OpenReplay.sessionId ?? undefined,
        'x-pusher-cid': PusherService.socketId
    };
}
