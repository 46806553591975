import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const AdminSalesRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: '/admin/sales/payments'
    },
    {
        path: 'payments',
        component: wrapRuntimeImport(() => import('./screens/payments.vue')),
        meta: { admin: true }
    },
    {
        path: 'payments/stripe-oauth',
        component: wrapRuntimeImport(() => import('./screens/payments/stripe-oauth.vue')),
        meta: { admin: true }
    },
    {
        path: 'quickbooks',
        component: wrapRuntimeImport(() => import('./screens/quickbooks.vue')),
        meta: { admin: true }
    },
    {
        path: 'fulfillment',
        component: wrapRuntimeImport(() => import('./screens/fulfillment.vue')),
        meta: { admin: true, ...makePermissionMeta('sales', 'fulfillmentEdit') }
    }
];
