<template>
    <div id="email-login-form">
        <div v-if="error" class="error-message" :class="errorType" :innerText="error"></div>

        <Loader v-if="isProcessing"></Loader>

        <form v-else-if="ssoButtonCallback">
            <button @click="ssoButtonCallback" class="primary">Continue with Single Sign On</button>
            <button @click="ssoButtonCallback = undefined">Cancel</button>
        </form>

        <form v-else ref="form" @submit.prevent="login">
            <input v-model="email" v-autofocus="!enablePassword" type="email" placeholder="e-mail address" />
            <input v-if="enablePassword" v-model="password" v-autofocus type="password" placeholder="password" />
            <button class="primary">Continue</button>

            <template v-if="!enablePassword">
                <button type="button" class="sso sso-microsoft" @click="startSsoLogin({ provider: 'azure' })">
                    <img src="/assets/login-ms.svg" /> Sign in with Microsoft
                </button>
                <button type="button" class="sso sso-google" @click="startSsoLogin({ provider: 'google' })">
                    <img src="/assets/login-google.svg" /> Sign in with Google
                </button>
            </template>
        </form>
    </div>
</template>

<script lang="ts" setup>
import { formatError, handleError, maskForm, sleep, unmaskForm } from '@signal24/vue-foundation';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { LoginApi } from '@/openapi-clients-generated/auth';
import Loader from '@/shared/components/loader.vue';
import { usePostMessageMonitor } from '@/shared/helpers/message.helpers';
import { Auth } from '@/shared/services/auth.service';
import { dataFrom } from '@signal24/openapi-client-codegen/browser';

const form = ref<HTMLFormElement>();
const error = ref<null | string>(null);
const errorType = ref<string | null>();
const email = ref('');
const password = ref('');

const preferredTenantId = Auth.lastTenantId ?? undefined;
const isProcessing = ref(false);
const ssoButtonCallback = ref<() => void>();
const enablePassword = ref(false);

const msgMonitor = usePostMessageMonitor('login');

function clearError() {
    errorType.value = null;
    error.value = null;
}

async function login() {
    if (!email.value.trim().length) {
        return;
    }
    if (enablePassword.value && !password.value.length) {
        return;
    }

    maskForm(form.value!);
    clearError();

    try {
        if (enablePassword.value) {
            const { data } = await LoginApi.postLoginPasswordLogin<true>({
                body: { email: email.value, password: password.value }
            });
            Auth.processLoginResponse(data);
        } else {
            const response = dataFrom(
                await LoginApi.postLoginLogin({
                    body: { email: email.value, redirectUrl: `${location.origin}/login/callback` }
                })
            );
            if (response.authorizeUrl) {
                startSsoLogin({ authorizeUrl: response.authorizeUrl });
            } else {
                unmaskForm(form.value!);
                enablePassword.value = true;
            }
        }
    } catch (err) {
        unmaskForm(form.value!);
        handleLoginError(err);
    }
}

async function startSsoLogin({ provider, authorizeUrl }: { provider?: 'azure' | 'google'; authorizeUrl?: string }) {
    clearError();

    const loginWindow = window.open(
        authorizeUrl ?? `/login/sso?provider=${provider}`,
        'loginSSO',
        'width=450,height=550,popup=true,menubar=no,toolbar=no,location=no,status=no'
    );

    if (!loginWindow) {
        ssoButtonCallback.value = () => {
            ssoButtonCallback.value = undefined;
            startSsoLogin({ provider, authorizeUrl });
        };
        return;
    }

    isProcessing.value = true;

    let windowClosed = false;
    const windowClosePromise = new Promise<void>(resolve => {
        const interval = setInterval(() => {
            try {
                if (loginWindow.closed) {
                    windowClosed = true;
                    clearInterval(interval);
                    resolve();
                }
            } catch {}
        }, 500);
    });
    const messagePromise = msgMonitor.waitForMessage<Record<string, string>>();
    await Promise.race([windowClosePromise, messagePromise]);

    // if the window was closed, we don't need to do anything
    if (windowClosed) {
        isProcessing.value = false;
        return;
    }

    const urlParams = await messagePromise;
    if (!urlParams) {
        return handleLoginError('Missing callback parameters', true);
    }

    if (urlParams.error) {
        return handleLoginError(`SSO error: ${urlParams.error}: ${urlParams.error_description}`, true);
    }

    if (!urlParams.code) {
        return handleLoginError('Missing code parameter', true);
    }

    const code = urlParams.code;
    const state = urlParams.state;

    // clear any existing session so that it's not included in the login request
    Auth.clearUserSession();

    try {
        const { data } = await LoginApi.postLoginSsoCallback<true>({
            body: { redirectUrl: `${location.origin}/login/callback`, code, state, preferredTenantId }
        });
        Auth.processLoginResponse(data);
    } catch (err) {
        return handleLoginError(err);
    }
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function handleLoginError(err: any, isCritical?: boolean) {
    if (err instanceof Error || isCritical) {
        handleError(err);
        error.value = String(err).includes('invalid_credentials')
            ? 'The e-mail address or password you provided is incorrect. Please try again. If you need further assistance, please contact support@zyno.app'
            : formatError(err);
    } else {
        error.value = err;
    }

    errorType.value = null;
    isProcessing.value = false;
}

const sessionLogoutCause = Auth.logoutCauseOnce;
if (sessionLogoutCause) {
    error.value = sessionLogoutCause;
    errorType.value = 'warning';
}
</script>

<style lang="scss" scoped>
#email-login-form {
    @apply max-w-xs w-screen;
}

form {
    @apply flex flex-col gap-2;
}

.sso {
    @apply bg-white border-gray-300 hover:bg-gray-200 flex gap-2;

    img {
        @apply max-w-6;
    }
}
</style>
