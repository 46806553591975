import { tracer } from '@/otel';
import { Logger } from '@/shared/services/logger';
import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/vue';
import { type OpenApiClient, type OpenApiClientOptions, OpenApiError } from '@signal24/openapi-client-codegen/browser';
import { UserError, configureVfOpenApiClient } from '@signal24/vue-foundation';

export function configureZynoOpenApiClient(name: string, client: OpenApiClient, options?: OpenApiClientOptions) {
    const logger = Logger.createScoped(`Api:${name}`);

    configureVfOpenApiClient(client, {
        ...options,

        wrapper(options, fn) {
            return new Promise((resolve, reject) => {
                return tracer.startActiveSpan('api', span => {
                    const traceId = span.spanContext().traceId;
                    logger.debug('Request', { traceId, method: options.method, url: options.url });
                    const promise = fn(options);
                    promise
                        .then(d => {
                            if ('error' in d) {
                                logger.warn('Response error', { traceId, status: d.response.status, error: d.error });
                            } else {
                                logger.debug('Response response', { traceId, status: d.response.status });
                            }
                            resolve(d);
                        })
                        .catch(reject)
                        .finally(() => span.end());
                });
            });
        },

        onError(err) {
            Sentry.withScope(scope => {
                const spanCtx = trace.getActiveSpan()?.spanContext();
                if (spanCtx) {
                    scope.setContext('trace', {
                        trace_id: spanCtx.traceId,
                        span_id: spanCtx.spanId
                    });
                }
                if (err instanceof OpenApiError) {
                    scope.setFingerprint(['OpenApiError', String(err).replace(/^Error: /, '')]);
                } else if (err instanceof UserError) {
                    scope.setFingerprint(['UserError', String(err)]);
                }
                Sentry.captureException(err);
            });
        }
    });
}
