import { onBeforeUnmount, onMounted, ref } from 'vue';

import { Logger } from '../services/logger';
import { IdleTimer, useIdleTimeout } from './idle.helpers';

const logger = Logger.createScoped('UpdateChecker');

export class UpdateChecker {
    private static localVersion = import.meta.env.VITE_APP_VERSION;
    private static updateCheckInterval: number | null = null;
    static subscribers = new Set<() => void>();
    static isUpdateAvailable = false;

    static setup() {
        logger.info(`👋 from ZynoSuite ${this.localVersion}`);
        this.updateCheckInterval = window.setInterval(this.checkForUpdate, 60_000);
    }

    static teardown() {
        if (this.updateCheckInterval) clearInterval(this.updateCheckInterval);
    }

    static checkForUpdate = async () => {
        if (this.isUpdateAvailable) {
            return true;
        }

        if (!this.localVersion || this.localVersion === '0.0.0') {
            return false;
        }

        try {
            const response = await fetch('/version.json');
            const availableVersion = await response.json();
            if (isVersionGreater(availableVersion, this.localVersion)) {
                logger.info(`🎉 ZynoSuite ${availableVersion} update is available.`);
                this.isUpdateAvailable = true;
                for (const sub of this.subscribers) sub();
                return true;
            }
            return false;
        } catch (err) {
            console.error('Failed to load version.json:', err);
            return false;
        }
    };
}

function isVersionGreater(maybeNewer: string, base: string) {
    const maybeNewerInts = maybeNewer.split('.').map(Number);
    const baseInts = base.split('.').map(Number);
    for (let i = 0; i < baseInts.length; i++) {
        if (maybeNewerInts[i] > baseInts[i]) {
            return true;
        }
    }
    return false;
}

export function useUpdateNotifier(onUpdateAvailable: () => void) {
    onMounted(() => {
        UpdateChecker.subscribers.add(onUpdateAvailable);
    });
    onBeforeUnmount(() => {
        UpdateChecker.subscribers.delete(onUpdateAvailable);
    });
}

export function useUpdateAvailableRef() {
    const retRef = ref(UpdateChecker.isUpdateAvailable);
    useUpdateNotifier(() => {
        retRef.value = true;
    });
    return retRef;
}

export function useIdleUpdateNotifier(timeoutMs: number, onIdleWithUpdates: () => void) {
    const checkConditions = () => {
        if (UpdateChecker.isUpdateAvailable && IdleTimer.getIdleMs() >= timeoutMs) {
            onIdleWithUpdates();
        }
    };
    useUpdateNotifier(checkConditions);
    useIdleTimeout({ timeoutMs, onIdle: checkConditions });
}

UpdateChecker.setup();
