<template>
    <div id="launcher">
        <div v-if="launcherItems.length" class="module-grid">
            <RouterLink v-for="app of launcherItems" :key="app.name" :to="app.path" class="launcher-button">
                <i :class="`${app.icon} fa-4x fa-fw`" />
                <span>{{ app.name }}</span>
                <ul v-if="app.subItems">
                    <li v-for="subItem of app.subItems" :key="subItem.name">
                        <RouterLink :to="subItem.path">
                            <i :class="`${subItem.icon} fa-lg fa-fw`" />
                            <span>{{ subItem.name }}</span>
                        </RouterLink>
                    </li>
                </ul>
            </RouterLink>
        </div>

        <div v-else class="no-apps">Hmm... it appears you haven't been assigned any permissions. Please contact your manager.</div>

        <!-- <Transition appear name="pop">
            <button v-show="true" class="all-apps-button">
                <i class="fas fa-grid fa-lg text-slate-900 mt-[2px]" />
                <span>All Apps</span>
            </button>
        </Transition> -->
    </div>
</template>

<script lang="ts" setup>
import { useAuthorizedLauncherItems } from './exports/launcher-items';

const launcherItems = useAuthorizedLauncherItems();
</script>

<style lang="scss" scoped>
#launcher {
    @apply relative flex-1 flex flex-col items-center justify-center;
}

.module-grid {
    @apply flex items-center justify-center flex-wrap gap-4 w-fit h-fit p-4 rounded-md;
}

.launcher-button {
    @apply relative flex flex-col items-center justify-center gap-4 w-36 h-36 rounded-lg bg-white hover:bg-slate-200 duration-150 border-none shadow-lg;

    i {
        @apply flex items-center justify-center text-slate-800;
    }

    span {
        @apply block text-lg text-slate-800;
    }

    ul {
        @apply absolute hidden top-full min-w-full pt-1;

        a {
            @apply flex items-center gap-2 bg-white p-2 text-center hover:bg-slate-200;
        }

        li:first-child a {
            @apply rounded-t-md;
        }

        li:last-child a {
            @apply rounded-b-md;
        }
    }

    &:hover ul {
        @apply block;
    }
}

.all-apps-button {
    @apply absolute bottom-4 flex gap-3 p-2 rounded-md bg-white hover:bg-slate-200 border-none shadow-lg justify-center;

    span {
        @apply text-lg text-slate-900 font-medium;
    }
}

.no-apps {
    @apply text-2xl text-red-200;
}
</style>
