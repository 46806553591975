<template>
    <div id="login-sso">
        <div v-if="error" class="error-message" :innerText="error"></div>
        <Loader v-else />
    </div>
</template>

<script lang="ts" setup>
import { LoginApi } from '@/openapi-clients-generated/auth';
import Loader from '@/shared/components/loader.vue';
import { postMessageToOpener } from '@/shared/helpers/message.helpers';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const error = ref<string>();
const isCallbackUrl = router.currentRoute.value.name === 'login:callback';

async function startSso() {
    const provider = router.currentRoute.value.query.provider as 'azure' | 'google';
    try {
        const { data } = await LoginApi.postLoginLogin({
            body: {
                provider,
                redirectUrl: `${location.origin}/login/callback`
            }
        });
        location.href = data!.authorizeUrl!;
    } catch (err) {
        alert(err);
    }
}

function doCallback() {
    postMessageToOpener('login', router.currentRoute.value.query);
    window.close();
}

onMounted(() => {
    if (!window.opener) {
        error.value = 'Could not detect login form. Please close the window and try again.';
        return;
    }

    if (isCallbackUrl) {
        doCallback();
    } else {
        startSso();
    }
});
</script>

<style lang="scss" scoped></style>
