import { hasRouteAccess } from '@/shared/helpers/permissions.helpers';
import { compact } from 'lodash';
import { computed } from 'vue';

export interface ILauncherItem {
    name: string;
    path: string;
    icon: string;
    subItems?: {
        name: string;
        path: string;
        icon: string;
    }[];
}

export const LauncherItems: ILauncherItem[] = [
    {
        name: 'Customers',
        path: '/crm',
        icon: 'fad fa-users',
        subItems: [{ name: 'Memberships', path: '/crm/memberships', icon: 'fad fa-id-card' }]
    },
    { name: 'Display', path: '/display', icon: 'fa fa-tv' },
    { name: 'Forms', path: '/forms', icon: 'fad fa-file-lines' },
    { name: 'Inventory', path: '/inventory', icon: 'fad fa-shelves' },
    {
        name: 'Sales',
        path: '/sales',
        icon: 'fad fa-dollar-sign',
        subItems: [
            { name: 'Gift Cards', path: '/sales/gift-cards', icon: 'fad fa-gift-card' },
            { name: 'Products', path: '/sales/config/products', icon: 'fad fa-boxes-stacked' },
            { name: 'Reports', path: '/sales/reports', icon: 'fad fa-file-chart-column' },
            { name: 'Transactions', path: '/sales/transactions', icon: 'fad fa-receipt' },
            { name: 'Fulfillment', path: '/sales/fulfillment', icon: 'fad fa-box' }
        ]
    },
    { name: 'Talk', path: '/talk', icon: 'fad fa-phone-alt' }
];

export function useAuthorizedLauncherItems() {
    return computed(() =>
        compact(
            LauncherItems.map(app => {
                const hasAccess = hasRouteAccess(app.path);
                const subItems = app.subItems?.filter(subItem => hasRouteAccess(subItem.path));

                if (!hasAccess && !subItems?.length) {
                    return null;
                }

                return { ...app, subItems, path: hasAccess ? app.path : '#' };
            })
        )
    );
}
