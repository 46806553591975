<template>
    <div id="nav-launcher">
        <div class="inner">
            <div v-if="currentApp?.subItems?.length" class="current-app">
                <RouterLink :to="currentApp.path" class="app">
                    <i :class="`${currentApp.icon} fa-lg fa-fw`" /><span>{{ currentApp.name }}</span>
                </RouterLink>
                <RouterLink v-for="subapp of currentApp.subItems" :key="subapp.name" :to="subapp.path" class="app">
                    <i :class="`${subapp.icon} fa-lg fa-fw`" /><span>{{ subapp.name }}</span>
                </RouterLink>
            </div>
            <div class="other-apps">
                <RouterLink v-for="app of otherApps" :key="app.name" :to="app.path" class="app">
                    <i :class="`${app.icon} fa-lg fa-fw`" /><span>{{ app.name }}</span>
                    <div v-if="app.subItems" class="subapps">
                        <RouterLink v-for="subItem of app.subItems" :key="subItem.name" :to="subItem.path">
                            <i :class="`${subItem.icon} fa-lg fa-fw`" />
                            <span>{{ subItem.name }}</span>
                        </RouterLink>
                    </div>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthorizedLauncherItems } from '../exports/launcher-items';

const route = useRoute();
const shouldHide = ref(false);

const launcherItems = useAuthorizedLauncherItems();
const currentApp = computed(() => launcherItems.value.find(app => route.path === app.path || route.path.startsWith(`${app.path}/`)));
const otherApps = computed(() => launcherItems.value.filter(app => currentApp.value !== app));
</script>

<style lang="scss" scoped>
.hide {
    @apply hidden;
}

.inner {
    @apply mt-2 bg-slate-600 rounded-md;
}

a {
    @apply p-3 pr-6 relative flex gap-2 items-center;

    &:hover {
        @apply bg-slate-500;

        .subapps {
            @apply flex flex-col;
        }
    }
}

.current-app {
    @apply border-b border-slate-500;
}

.subapps {
    @apply absolute bg-slate-600 rounded-md rounded-tl-none left-full top-0 hidden;
}
</style>
