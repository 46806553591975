<template>
    <div id="app">
        <div v-if="rootStore.globalError" id="global-error" v-text="rootStore.globalError" />

        <Transition v-else name="fade" mode="out-in">
            <Layout v-if="shouldShowLogin">
                <Login />
            </Layout>
            <Loader v-else-if="shouldShowLoader" id="preloader" size="2xl"></Loader>
            <Layout :show-qab="!isPublicRoute" v-else>
                <router-view v-slot="{ Component }" :key="routerViewKey">
                    <Transition :name="transition" mode="out-in">
                        <div v-if="!routeExists" id="error">The requested URL does not exist.</div>
                        <div v-else-if="!isAuthorized" id="error">You don't have access to the requested URL.</div>
                        <component :is="Component" v-else />
                    </Transition>
                </router-view>
            </Layout>
        </Transition>

        <OverlayContainer />

        <div id="popover-portal"></div>

        <Transition name="fade" mode="out-in">
            <div v-if="rootStore.globalLoader" id="root-loader">
                <div class="bg-wrap">
                    <Loader />
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" setup>
import { OverlayContainer } from '@signal24/vue-foundation';
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import Layout from '@/shared/components/layout.vue';
import Loader from '@/shared/components/loader.vue';

import { GlobalState } from './global';
import Login from './modules/core/login.vue';
import { usePageTitleHelpers } from './shared/helpers/page-title.helpers';
import { hasRouteAccess } from './shared/helpers/permissions.helpers';
import { Auth } from './shared/services/auth.service';
import { Freshdesk } from './shared/services/freshdesk';
import { SessionManagement } from './shared/services/session-management.service';
import { useStore } from './store';

const rootStore = useStore();
const router = useRouter();

const transition = ref<'scale' | ''>('scale');
router.beforeEach(async (to, from) => {
    if (from.path.substring(1).replace(/\/.*$/, '') === to.path.substring(1).replace(/\/.*$/, '')) {
        transition.value = '';
    } else {
        transition.value = 'scale';
    }
});

const isRouterReady = ref(false);
router.isReady().then(() => {
    isRouterReady.value = true;
});

const hasLoadedUserSession = ref(false);

const routeExists = computed(() => router.currentRoute.value.matched.length > 0);
const hasLoadedPermissions = computed(() => GlobalState.permissions.value !== null);
const isAuthorized = computed(() => hasRouteAccess(router.currentRoute.value.path));
const isPublicRoute = computed(() => router.currentRoute.value.meta.public);
const shouldShowLogin = computed(() => {
    if (!isRouterReady.value) return false;
    if (!hasLoadedUserSession.value) return false;
    if (isPublicRoute.value) return false;
    if (GlobalState.user.value) return false;
    return true;
});
const shouldShowLoader = computed(() => {
    if (!isRouterReady.value) return true;
    if (!hasLoadedUserSession.value) return true;
    if (isPublicRoute.value) return false;
    if (!hasLoadedPermissions.value) return true;
    return false;
});

const hasUserSwitched = ref(false);

watch(GlobalState.user, (_, previousUser) => {
    document.body.classList[GlobalState.user.value ? 'add' : 'remove']('signed-in');
    hasUserSwitched.value = hasUserSwitched.value || !!previousUser;
});

watch(hasLoadedPermissions, () => {
    if (!GlobalState.user.value) return;
    if (!hasLoadedPermissions.value) return;
    if (hasUserSwitched.value && !isAuthorized.value) {
        router.replace('/');
    }
    hasUserSwitched.value = false;
});

const routerViewKey = computed(() => {
    return GlobalState.tenant.value?.id ?? 'unauthed';
});

usePageTitleHelpers();

onMounted(() => {
    Freshdesk.prepare();
    Auth.loadExistingSession().then(() => {
        hasLoadedUserSession.value = true;
    });
});

SessionManagement.install();
</script>

<style lang="scss">
@use './shared/styles/base.scss' as *;

@import '@fontsource/inter/latin-200.css';
@import '@fontsource/inter/latin-300.css';
@import '@fontsource/inter/latin-400.css';
@import '@fontsource/inter/latin-500.css';
@import '@fontsource/inter/latin-600.css';
@import '@fontsource/inter/latin-700.css';
@import '@fontsource/inter/latin-800.css';
@import '@fontsource/inter/latin-900.css';
@import '@vuepic/vue-datepicker/dist/main.css';

#app {
    @apply flex-1 flex;
}

#global-error {
    @apply flex-1 flex justify-center items-center bg-gray-800 text-2xl text-red-300;
}

#popover-portal {
    @apply absolute w-full h-full top-0 left-0 pointer-events-none flex;
}

#preloader {
    @apply flex-1 justify-self-center self-center;
}

#root-loader {
    @apply absolute top-0 left-0 w-full h-full flex items-end justify-center p-4;

    .bg-wrap {
        @apply bg-slate-100 rounded-full shadow-lg;
    }
}

#error {
    @apply items-center justify-center text-xl text-red-200;
}
</style>
