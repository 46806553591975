import { useStore } from '@/store';
import { computed, watch, watchEffect } from 'vue';

export function usePageTitleHelpers() {
    const rootStore = useStore();

    const titlePrefix = computed(() => {
        if (import.meta.env.MODE !== 'production') {
            const env = import.meta.env.MODE ?? 'development';
            const envKey = env.substring(0, 1).toUpperCase();
            return `[${envKey}] `;
        }
        return '';
    });

    const documentTitle = computed(() => {
        if (rootStore.titleOverride) {
            return `${titlePrefix.value}${rootStore.titleOverride}`;
        }
        const baseTitle = `Zyno${rootStore.rootAppName ?? 'Suite'}`;
        const titleSuffix = rootStore.subAppName ? ` - ${rootStore.subAppName}` : '';
        return `${titlePrefix.value}${baseTitle}${titleSuffix}`;
    });

    watchEffect(() => {
        document.title = documentTitle.value;
    });
}
