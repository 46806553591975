<template>
    <div id="login">
        <FLogoScroller class="logo" />
        <div class="pane login-form">
            <InstallLoginForm v-if="isInstalledClient" />
            <EmailLoginForm v-else />
        </div>
        <div id="login-terms">
            By signing in, you agree to our
            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=e8482c32-22e5-414b-bafa-f8215327847d" target="_blank">Terms of Use</a>
            and
            <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=3aa9812c-cb8e-4f20-b3df-128bb3d5122b" target="_blank"
                >Privacy Policy</a
            >.
        </div>
    </div>
</template>

<script lang="ts" setup>
import { showWait } from '@signal24/vue-foundation';
import { onMounted } from 'vue';

import { useIdleUpdateNotifier } from '@/shared/helpers/update.helpers';

import { Logger } from '@/shared/services/logger';
import { DesktopBridge } from '../desktop/helpers/bridge';
import { PusherService } from './exports/pusher';
import EmailLoginForm from './login/f-email-login-form.vue';
import InstallLoginForm from './login/f-install-login-form.vue';
import FLogoScroller from './login/f-logo-scroller.vue';

const isInstalledClient = DesktopBridge.isWithinZynoDesktop();

onMounted(() => {
    PusherService.disconnect();
});

const logger = Logger.createScoped('Login');
useIdleUpdateNotifier(5_000, () => {
    logger.info('Reloading ZynoSuite for update');
    showWait('Updating ZynoSuite...');
    window.location.reload();
});
</script>

<style lang="scss" scoped>
#login {
    @apply flex-1 flex items-center justify-center;
}

.pane {
    @apply mt-12;
}

#login-terms {
    @apply absolute bottom-12 text-sm mt-4 text-slate-500;

    a {
        @apply text-sm text-slate-400;
    }
}
</style>
