import { onMounted, onUnmounted } from 'vue';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function postMessageToOpener(name: string, message: any) {
    window.opener.postMessage({ [`zs:cb:${name}`]: message }, location.origin);
}

export function usePostMessageMonitor(name: string) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let pendingPromiseResolver: ((value: any) => void) | null = null;

    const handleMessage = (event: MessageEvent) => {
        if (event.origin !== location.origin) return;
        if (typeof event.data !== 'object') return;
        const data = event.data[`zs:cb:${name}`];
        if (!data) return;
        pendingPromiseResolver?.(data);
        pendingPromiseResolver = null;
    };

    onMounted(() => {
        window.addEventListener('message', handleMessage);
    });
    onUnmounted(() => {
        window.removeEventListener('message', handleMessage);
    });

    return {
        waitForMessage: <T>() =>
            new Promise<T>(resolve => {
                pendingPromiseResolver = resolve;
            })
    };
}
